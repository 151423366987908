exports.components = {
  "component---src-components-templates-articles-template-articles-template-js": () => import("./../../../src/components/Templates/ArticlesTemplate/ArticlesTemplate.js" /* webpackChunkName: "component---src-components-templates-articles-template-articles-template-js" */),
  "component---src-components-templates-collection-template-collection-template-js": () => import("./../../../src/components/Templates/CollectionTemplate/CollectionTemplate.js" /* webpackChunkName: "component---src-components-templates-collection-template-collection-template-js" */),
  "component---src-components-templates-template-exhibitions-template-exhibitions-js": () => import("./../../../src/components/Templates/TemplateExhibitions/TemplateExhibitions.js" /* webpackChunkName: "component---src-components-templates-template-exhibitions-template-exhibitions-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artykuly-js": () => import("./../../../src/pages/artykuly.js" /* webpackChunkName: "component---src-pages-artykuly-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kolekcje-modeli-js": () => import("./../../../src/pages/kolekcje-modeli.js" /* webpackChunkName: "component---src-pages-kolekcje-modeli-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-koszyk-js": () => import("./../../../src/pages/koszyk.js" /* webpackChunkName: "component---src-pages-koszyk-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-podziekowanie-js": () => import("./../../../src/pages/podziekowanie.js" /* webpackChunkName: "component---src-pages-podziekowanie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-sklepu-js": () => import("./../../../src/pages/regulamin-sklepu.js" /* webpackChunkName: "component---src-pages-regulamin-sklepu-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */),
  "component---src-pages-terminarz-js": () => import("./../../../src/pages/terminarz.js" /* webpackChunkName: "component---src-pages-terminarz-js" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-exhibition-page-js": () => import("./../../../src/templates/exhibition-page.js" /* webpackChunkName: "component---src-templates-exhibition-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

